* {
    margin-block-start: 0;
    margin-block-end: 0;
}
#container {
    position: fixed;
    top: 0;
    width: 100vw;
    height: 13.5rem;
    background: var(--lightBg);
    box-shadow: 0 0 0.6rem 0.3rem #ccc;
    z-index: 10;
    transition: 500ms;
}

#container:hover {
    opacity: 1 !important;
}

#first_row {
    margin-top: 3.5rem;
    margin-bottom: 2.4rem;
}

#first_row * {
    margin-top: 0;
    margin-bottom: 0;
}

#srch_input {
    height: 3.2rem;
    width: 40vw;
    border: 1px solid #888888;
    border-radius: 3.2rem;
    padding-left: 1.75rem;
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    font-size: 1.1rem;
    background: none;
    color: var(--myDarkGrey);
}

#srch_input:focus, #srch_input:active {
    color: var(--myBlack);
    outline: none;
}

#btn {
    height: 3rem;
    border-radius: 1.5rem;
    padding: 0 2rem;
    margin: 1.5rem 0.5rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--myWhite);
    text-align: center;
}
body[mode="dark"] #btn {
    background-color: #303134;
}
body[mode="light"] #btn {
    background-color: var(--myBlack);
}

#logotype {
    font-size: 1.9rem;
    color: var(--myBlack);
    margin-left: 5rem;
}

#second_row {
    margin-left: 5rem;
    justify-content: space-between;
    width: 90vw;
}

#second_row p {
    font-size: 0.9rem;
    font-weight: 500;
}

#select, #cancel { 
    width: 115px;
    height: 30px;
    font-size: 0.6rem;
    font-weight: 600;
    border-radius: 30px;
    border: 1.75px solid var(--myBlack);
    color: var(--myBlack);
    background: none;
    position: absolute;
    bottom: 0;
    right: 0;
    margin-right: 5vw;
    margin-bottom: 0.5rem;
    transition: box-shadow 0.5s linear;
}

#cancel {
    display: none;
}

#select:hover, #cancel:hover { 
    box-shadow: 0 0 2px 2px var(--myLightGrey) inset;
}

#select:active, #select:focus { 
    box-shadow: 0 0 2px 2px var(--myBlack) inset;
}