body { margin: 0; }
text { 
  font-size: 1.75rem;
  text-transform: uppercase;
}
svg text {
  pointer-events: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
svg text::selection {
  background: none;
  pointer-events: none;
}

#maps_container {
    color: var(--myWhite);
    background: black;
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
}

#center {
    margin: auto;
}