#btn {
    height: 2.5rem;
    width: 2.5rem;
    text-align: center;
    font-weight: 900;
    font-size: 1.25rem;
    padding: 0;
    color: var(--myWhite);
    position: absolute;
    right: 2.5rem;
    border-radius: 1.25rem;
    animation: slide-down 4.5s ease-in-out forwards;
  }

  body[mode="dark"] #btn {
    background-color: var(--myWhite);
    color: var(--myBlack);
  }
  body[mode="light"] #btn {
    background-color: var(--myBlack);
    color: var(--myWhite);
  }

  @keyframes slide-down {
    0% {top: -3rem; }
    80% { top: -3rem; }
    100% {top: 2.5rem;}
  }