/* DE-STYLING */
body { overflow-x: hidden; }

ul { list-style: none; }

a,
a:hover,
a:active,
a:visited,
a:focus {
  color: unset;
  text-decoration: none;
  overflow: hidden; /* Fix de un margen raro en los vectores de redes sociales */
}

button {
  border: none;
  outline: none;
}

/* PRESET STYLING */
button { font-family: 'Raleway'; }

/* COLOR VARIABLES */
body {
  --myWhite: #fff;
  --myBlack: #18191E;
  --myLightGrey:#888888;
  --myDarkGrey: #303134;
  --lightBg: #F3F3F3;
}

/* FLEXBOX STUFF */
.flex { display: flex; }
.row { flex-direction: row; }
.column { flex-direction: column; }
.evenly { justify-content: space-evenly; }
.center-x { justify-content: center; }
.center-y { align-items: center; }
.force-center { margin: auto; }

/* PADDING AND MARGINS */
.m0 { margin: none; }
.p0 { padding: none; }

/* BUTTONS */

/* OTHER */
.whole-page {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background: var(--lightBg);
}
body[mode="dark"] .whole-page {
  background-color: var(--myBlack);
}
body[mode="light"] .whole-page {
  background-color: var(--lightBg);
}

.under-header {
  margin-top: 6rem;
}

.mag-glass {
  position: absolute;
  left: 1.5rem;
  margin: auto;
}

.p500 {
  font-size: 0.9rem;
  font-weight: 500;
}

.checkring {
  display: none;
}
