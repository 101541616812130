
#title {
    font-size: 4.5rem;
    margin-block: 0.4em;
    animation: fade-in 3s ease-in;
}
body[mode="dark"] #title {
  color: white;
}
body[mode="light"] #title {
  color: var(--myBlack);
}

#inputFrame { position: relative; }
  
#input {
  height: 3.2rem;
  width: 50vw;
  border: 1px solid;
  border-radius: 3.2rem;
  padding-left: 3.5rem;
  font-size: 1.1rem;
  background: none;
}
body[mode="dark"] #input {
  border-color: #A6A6A6;
  color: white
}
body[mode="light"] #input {
  border-color: #888888;
  color: var(--myBlack)
}
  
#btn {
  height: 3rem;
  border-radius: 1.5rem;
  padding: 0 2rem;
  margin: 1.5rem 0.5rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--myWhite);
  text-align: center;
}
body[mode="dark"] #btn {
  background-color: #303134;
}
body[mode="light"] #btn {
  background-color: var(--myBlack);
}

@keyframes fade-in {
  0% { opacity: 0 }
  20% { opacity: 0 }
  100% { opacity: 1 }
}