#container {
    height: fit-content;
    min-height: 100vh;
}

#results { 
    overflow-y: auto;
    margin-top: 7rem;
    padding-top: 7rem;
    height: fit-content;
    z-index: 1;
}

#thread_col {
    position: relative;
    width: 90vw;
    background: #E5E5E5;
    border-radius: 8px;
    color: var(--myBlack);
    padding: 0 1rem;
    margin-bottom: 1.2rem;
}

#thread_expand {
    position: absolute;
    top: 0.8rem;
    right: 1.5rem;
    transition: 400ms;
}

#thread_expand path, #thread_expand svg { /* Disable troubling SVG path click events, and static (non-animated) svg click event */
    pointer-events: none;
}

#thread_body {
    display: none;
    position: relative;
    background: #FAFAFA;
    border-radius: 8px;
    padding: 1rem;
    margin-bottom: 1rem;
}

#thread_img_container {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 10rem;
    max-height: 11rem;
    width: 11.5rem;
    border-radius: 5px;
}

#thread_wiki {
    height: 10rem;
    width: 11.5rem;
    background-size: contain;
    background-origin: url('../../assets/wiki.jpeg');
    border-radius: 5px;
}

#thread_article { 
    padding-left: 1.25rem;
    max-width: 60vw;
}

#thread_article p {
    line-height: 1.5rem;
}

#thread_title {
    font-size: 1.25rem;
    font-weight: 600;
    margin-block-end: 0.5rem;
    margin-block-start: 0.5rem;
}

#keyw_margins {
    margin-top: 1rem;
    margin-bottom: 1.2rem;
}

#checkring {
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 15px;
    height: 15px;
    background-color: none;
    border-radius: 15px;
    border: 1px solid var(--myBlack);
    box-shadow: 0 0 2px var(--myBlack) inset;
    appearance: none;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
}

#checkring:checked {
    border: 2px solid var(--myWhite);
    background-color: var(--myBlack);
    box-shadow: 0 0 4px var(--myBlack);
}
